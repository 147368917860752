<template>
  <div class="page">
    <Navbar title="个人房产" :callback="back" type="SMP" />
    <div class="house" v-for="house in houseList" :key="house.id">
      <van-row>
        <van-col span="20" class="address">{{ house.address }}</van-col>
        <van-col span="4"
          ><van-tag :color="COLOR" plain @click="updateHouse(house.seq)"
            >修改</van-tag
          >
        </van-col>
      </van-row>
      <van-row>
        <van-col span="10" class="area">平米：{{ house.area }} ㎡</van-col>
        <van-col span="10" class="worth">价值：{{ house.worth }} 万</van-col>
        <van-col span="4"
          ><van-tag type="danger" plain @click="deleteHouse(house.seq)"
            >删除</van-tag
          ></van-col
        >
      </van-row>
    </div>
    <van-row class="no-record" v-if="houseList.length <= 0">
      <van-col span="24">
        <van-image
          width="103"
          height="103"
          :src="require('../../../../assets/images/home/no-record.png')"
        ></van-image
      ></van-col>
    </van-row>
    <van-row class="btn">
      <van-col span="24">
        <van-button
          :color="COLOR"
          icon="plus"
          size="small"
          @click="createHouse()"
          >新增房产</van-button
        >
      </van-col>
    </van-row>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Icon, Tag, Image } from 'vant'
import Loading from '../../common/Loading.vue'
import Navbar from '../../common/Navbar.vue'
export default {
  components: {
    Loading: Loading,
    Navbar: Navbar,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Image.name]: Image
  },
  data () {
    return {
      loadingShow: false,
      personCode: '',
      houseList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.retrieveHouseList()
  },
  methods: {
    async retrieveHouseList () {
      var pd = { personCode: this.personCode, group: 'HOS', type: 'DTL' }
      this.houseList = []
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var content = JSON.parse(element.content)
          var house = { seq: element.seq, address: content.address, regionCode: content.regionCode, area: content.area, worth: content.worth }
          this.houseList.push(house)
        })
      }
    },
    createHouse () {
      this.$router.push({ path: '/mde/people/person/dtlHouseEdit', query: { personCode: this.personCode, seq: this.houseList.length + 1 } })
    },
    updateHouse (seq) {
      this.$router.push({ path: '/mde/people/person/dtlHouseEdit', query: { personCode: this.personCode, seq: seq } })
    },
    deleteHouse (seq) {
      this.$dialog.confirm({
        title: '删除确认',
        message: '确认删除记录吗?'
      })
        .then(() => {
          this.deleteHouseExecute(seq)
        })
    },
    async deleteHouseExecute (seq) {
      var pd = { key: 'PSN_HOS_ITEM', personCode: this.personCode, type: 'DTL', seq: seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/deletePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.retrieveHouseList()
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '删除失败:' + res.msg
        })
      }
    },
    back () {
      var url = window.sessionStorage.getItem(this.SESSION_BACKURL)
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.house {
  padding: 10px 20px;
  height: 40px;
  border-bottom: 1px solid #eee;
  text-align: left;
  .address {
    font-size: 14px;
    font-weight: 600;
    height: 20px;
    line-height: 20px;
  }
  .area {
    height: 20px;
    line-height: 20px;
  }
  .worth {
    height: 20px;
    line-height: 20px;
  }
}
.btn {
  margin-top: 10px;
}
</style>
